var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section section-banner food-banner"},[_c('div',{staticClass:"items"},[_c('div',{staticClass:"item"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('food-menu.main_page'))}}),(_vm.user.class === 'c' || _vm.user.class === 'ss')?_c('picture',[_c('source',{attrs:{"srcset":require("@/assets/img/food-hero-business-m.jpg"),"media":"(max-width: 1023px)"}}),_c('source',{attrs:{"srcset":require("@/assets/img/food-hero-business-s.jpg"),"media":"(max-width: 767px)"}}),_c('img',{attrs:{"src":require("@/assets/img/food-hero-business.jpg"),"width":"100%","height":"100%","alt":""}})]):_c('picture',[_c('source',{attrs:{"srcset":require("@/assets/img/food-hero-econom-m.jpg"),"media":"(max-width: 1023px)"}}),_c('source',{attrs:{"srcset":require("@/assets/img/food-hero-econom-s.jpg"),"media":"(max-width: 767px)"}}),_c('img',{attrs:{"src":require("@/assets/img/food-hero-econom.jpg"),"width":"100%","height":"100%","alt":""}})])])])]),(_vm.user.class === 'c' || _vm.user.class === 'ss')?_c('section',{staticClass:"section section-localnav"},[_c('nav',{staticClass:"tabnav"},[_c('ul',{staticClass:"tabnav-items"},[_vm._m(0),_c('li',{staticClass:"tabnav-item tabnav-first-item",class:{ active: !_vm.alcohol }},[_c('em'),_c('router-link',{staticClass:"tabnav-link custom",attrs:{"to":{
              name: 'Menu',
              query: _vm.$route.query.json
                ? { json: _vm.$route.query.json, class: _vm.$route.query.class }
                : {}
            }}},[_vm._v(_vm._s(_vm.$t("food-menu.main")))])],1),_c('li',{staticClass:"tabnav-item tabnav-last-item",class:{ active: _vm.alcohol }},[_c('em'),_c('router-link',{staticClass:"tabnav-link custom",attrs:{"to":{
              name: 'Menu',
              params: { alcohol: 'alcohol' },
              query: _vm.$route.query.json
                ? { json: _vm.$route.query.json, class: _vm.$route.query.class }
                : {}
            },"active-class":"active"}},[_vm._v(_vm._s(_vm.$t("food-menu.wine")))])],1),_vm._m(1)])])]):_vm._e(),(!_vm.alcohol && _vm.items.food && _vm.items.food.length)?_c('section',{staticClass:"section food-menu"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"page-description"},[_vm._v(" "+_vm._s(_vm.$t("food-menu.description"))+" ")]),_c('form',_vm._l((_vm.items.food.find(
            function (it) { return it.class ===
              (_vm.user.class === 'ss'
                ? 'business'
                : _vm.user.class === 'c'
                ? 'comfort'
                : 'economy'); }
          ).rations),function(item,index){return _c('div',{key:index,staticClass:"eating-time"},[_c('figure',[_c('img',{attrs:{"src":!!item.image
                  ? 'data:image/jpeg;base64,' + item.image
                  : require('@/assets/img/business-dinner-new.jpg'),"alt":""}}),_c('figcaption',[_vm._v(_vm._s(item.title))])]),_vm._l((item.sections),function(section,iSection){return _c('div',{key:iSection,staticClass:"item"},[(
                !section.selective ||
                  (_vm.user.class !== 'c' && _vm.user.class !== 'ss')
              )?_c('span',[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(section.title))]),_vm._l((section.items),function(it,idx){return _c('span',{key:idx},[_c('strong',[_vm._v(_vm._s(it.title))]),(it.description)?_c('p',{staticClass:"descr"},[_vm._v(" "+_vm._s(it.description)+" ")]):_vm._e()])})],2):_c('span',{staticClass:"parent"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(section.title))]),_vm._l((section.items),function(it,idx){return _c('div',{key:idx,staticClass:"dl"},[_c('div',{staticClass:"dt"},[_c('div',{class:section.selective === 1
                        ? 'input-radio'
                        : 'input-checkbox'},[_c('input',{attrs:{"name":iSection + '' + item.id,"disabled":_vm.orderExist(item.id),"type":"checkbox","id":it.title},domProps:{"checked":_vm.orderExist(item.id) &&
                          ((_vm.selectedFoodMenu[item.id] &&
                            _vm.selectedFoodMenu[item.id].find(
                              function (menu) { return menu === it.id; }
                            )) ||
                            (_vm.orderExist(item.id).selectMenuFood[item.id] &&
                              _vm.orderExist(item.id).selectMenuFood[
                                item.id
                              ].find(function (menu) { return menu === it.id; }))),"value":it.id},on:{"change":function($event){return _vm.selectedFoodMenuAction(
                          it.id,
                          section.items,
                          section.selective === 1
                            ? 'custom-radio'
                            : 'checkbox',
                          item.id,
                          $event
                        )}}}),_c('span',{staticClass:"checkmark",class:{ disabled: _vm.orderExist(item.id) }})])]),_c('div',{staticClass:"dd"},[_c('label',{attrs:{"for":it.title}},[_c('strong',{class:{
                        disabled:
                          _vm.orderExist(item.id) &&
                          !(
                            (_vm.selectedFoodMenu[item.id] &&
                              _vm.selectedFoodMenu[item.id].find(
                                function (menu) { return menu === it.id; }
                              )) ||
                            (_vm.orderExist(item.id).selectMenuFood[item.id] &&
                              _vm.orderExist(item.id).selectMenuFood[
                                item.id
                              ].find(function (menu) { return menu === it.id; }))
                          )
                      }},[_vm._v(_vm._s(it.title))]),(it.description)?_c('p',{staticClass:"descr"},[_vm._v(" "+_vm._s(it.description)+" ")]):_vm._e()])])])})],2)])}),(
              (_vm.user.class === 'c' || _vm.user.class === 'ss') && _vm.isHaveSelective
            )?_c('div',{staticClass:"item buttons"},[_c('input',{staticClass:"button",class:{
                'disabled-custom': _vm.orderExist(item.id)
              },attrs:{"type":"button","disabled":_vm.orderExist(item.id),"value":_vm.orderExist(item.id)
                  ? _vm.$t('food-menu.save_complete')
                  : _vm.$t('food-menu.save')},on:{"click":function($event){return _vm.saveMenuOrder(item.id)}}})]):_vm._e()],2)}),0),(_vm.user.class === 'c' || _vm.user.class === 'ss')?_c('div',{staticClass:"thanks"},[_vm._v(" "+_vm._s(_vm.$t("food-menu.complete"))+" ")]):_vm._e(),_c('div',{staticClass:"sorry"},[_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("food-menu.sorry1")))]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$t("food-menu.sorry2")))])])])])]):_vm._e(),(_vm.alcohol && _vm.items.food && _vm.items.food.length)?_c('section',{staticClass:"section food-menu"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"page-description",domProps:{"innerHTML":_vm._s(_vm.$t('food-menu.wine_description'))}}),_vm._l((_vm.uniqueAlcType),function(itemAlc,indexAlc){return _vm._l((_vm.items.alcohol.filter(
            function (it) { return it.contentType === itemAlc; }
          )),function(item,index){return _c('div',{key:("menu_" + itemAlc + "_" + index),staticClass:"menu-block",class:{
            'last-item':
              !_vm.uniqueAlcType[indexAlc + 1] &&
              !_vm.items.alcohol.filter(function (it) { return it.contentType === itemAlc; })[
                index + 1
              ]
          }},[_c('h2',[_vm._v(_vm._s(item.name))]),(itemAlc === 'wine')?_c('span',_vm._l((item.elements),function(it,idx){return _c('div',{key:idx,staticClass:"grid"},[_c('div',{staticClass:"col-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":'data:image/jpeg;base64,' + it.image,"alt":""}})]),_c('div',{staticClass:"col-info"},[_c('strong',[_vm._v(_vm._s(it.title))]),_c('dl',[_vm._l((it.specifications),function(spec,specIdx){return _c('dl',{key:specIdx},[_c('dt',[_vm._v(_vm._s(spec.title))]),_c('dd',[_vm._v(_vm._s(spec.description))])])}),_c('dt',[_vm._v(" "+_vm._s(it.description)+" ")])],2)])])}),0):(itemAlc === 'drinks')?_c('span',[_c('div',{staticClass:"item"},_vm._l((item.elements),function(it,idx){return _c('strong',{key:(itemAlc + "_" + idx)},[_vm._v(_vm._s(it.description)+" ")])}),0)]):_c('span',_vm._l((item.elements),function(it,idx){return _c('div',{key:(itemAlc + "_" + idx),staticClass:"item"},[(itemAlc === 'drinks')?_c('span',_vm._l((item.elements),function(it,idx){return _c('strong',{key:(itemAlc + "_" + idx)},[_vm._v(_vm._s(it.description)+" ")])}),0):(itemAlc === 'spirits')?_c('span',[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(it.title))]),_c('strong',[_vm._v(_vm._s(it.description))])]):_c('span',[_c('strong',[_vm._v(_vm._s(it.title))]),_c('p',{staticClass:"descr"},[_vm._v(_vm._s(it.description))])])])}),0)])})}),_c('div',{staticClass:"thanks"})],2)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])}]

export { render, staticRenderFns }